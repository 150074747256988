import { notification } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setToastMessage } from "../../store/features/Common.slice";
import { STATUS } from "../../utils/constants/common";
import {
  ToastCloseIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
} from "../Icons/Icons";
import { GetFromLink } from "../../service/api";
import { downloadDoc } from "../../utils/utils";

// Null will use the default Icon provided by Ant Design.
const TOAST_ICONS = {
  success: <ToastSuccessIcon />,
  info: <ToastInfoIcon />,
  warning: null,
  error: null,
};

export const ToastMessage = () => {
  const {
    showToast,
    toastMessage,
    toastType,
    toastDescription,
    toastCategory,
    isToastClickable,
  } = useAppSelector((state) => state.CommonReducer);
  const { newNotifications } = useAppSelector(
    (state) => state.NotificationReducer
  );

  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: 3 },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showToast) {
      api.open({
        message: toastMessage,
        description: toastDescription,
        className: `${!toastCategory ? "toast-message" : "notification"} ${
          isToastClickable && "cursor-pointer"
        }`,
        style: {
          minWidth: "34rem",
          width: "auto",
          fontSize: "1.2rem",
          color: "white",
        },
        duration: 4,
        placement: !toastCategory ? "bottomRight" : "topRight",
        type: toastType,
        icon: toastType ? TOAST_ICONS[toastType] : null,
        closeIcon: <ToastCloseIcon />,
        onClick: () => handleToastClick(),
      });
      dispatch(
        setToastMessage({
          showToast: false,
          toastMessage: "",
          toastType: STATUS.SUCCESS,
          toastDescription: "",
          category: "",
          clickable: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showToast,
    api,
    dispatch,
    toastMessage,
    toastType,
    toastDescription,
    toastCategory,
    isToastClickable,
  ]);

  const handleToastClick = () => {
    dispatch(GetFromLink("pdf/download", `${newNotifications?.link}`)()).then(
      (res: any) => {
        if (res?.payload?.status === STATUS.SUCCESS) {
          const { detail } = res?.payload?.data;
          downloadDoc(detail);
        }
      }
    );
  };

  return <>{contextHolder}</>;
};
